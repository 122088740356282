import { Box, BoxProps, Button, Typography } from '@mui/material';

import WarnSVG from '@/assets/icons/warn.svg';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';

interface IWalletConnectButton {
  children: React.ReactNode;
}

const WalletConnectButton: React.FC<IWalletConnectButton & BoxProps> = ({ children, sx }) => {
  const { openConnectModal } = useConnectModal();
  const { address } = useAccount();
  return address ? (
    children
  ) : (
    <Button
      variant="contained"
      fullWidth
      disableElevation
      sx={{
        height: '40px',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'capitalize',
        ...sx,
      }}
      onClick={openConnectModal}
    >
      Connect Wallet
    </Button>
  );
};

export default WalletConnectButton;
