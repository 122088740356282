'use client';

import { GetCollectionDetailData, GetEthscriptionsData, GetOrderByCartDataitem } from '@/services/marketpalce/types';
import { proxy } from 'valtio';
import { IBulkEthscriptionsItem } from './BulkListingStore';

export interface IEthscriptionsStore {
  listedList: GetEthscriptionsData;
  ownerList: GetEthscriptionsData;
  collectionDetail?: GetCollectionDetailData;
}

export const store = proxy<IEthscriptionsStore>({
  listedList: {
    ethscriptions: [],
    page: {
      size: 50,
      index: 1,
      total: '0',
    },
  },
  ownerList: {
    ethscriptions: [],
    page: {
      size: 50,
      index: 1,
      total: '0',
    },
  },
  collectionDetail: undefined,
});

export const setListedList = async (listedList: GetEthscriptionsData) => {
  store.listedList = listedList;
};

export const removeListedItem = async (sweepCart: GetOrderByCartDataitem[]) => {
  sweepCart.forEach((item) => {
    const _ethsFilter = store.listedList.ethscriptions.filter(
      (ethscriptionItem) => ethscriptionItem.order.orderId != item.order.orderHash,
    );
    store.listedList = { ...store.listedList, ethscriptions: _ethsFilter };
  });
};

export const setOwnerList = async (ownerList: GetEthscriptionsData) => {
  store.ownerList = ownerList;
};

export const removeOwnerItem = async (ethsList: IBulkEthscriptionsItem[]) => {
  ethsList.forEach((item) => {
    const _ethsFilter = store.ownerList.ethscriptions.filter(
      (ethscriptionItem) => ethscriptionItem.order.ethscriptionId != item.order.ethscriptionId,
    );
    store.ownerList = { ...store.ownerList, ethscriptions: _ethsFilter };
  });
};

export const setCollectionDetail = async (collectionDetail?: GetCollectionDetailData) => {
  store.collectionDetail = collectionDetail;
};
