'use client';

import Erc20View from './Erc20View';
import { categoryType } from '@/services/marketpalce/types';

import NftView from './NftView';
import DomainView from './DomainView';

interface IEthscriptionLabel {
  category: categoryType;
  icon: string;
  collectionName: string;
  domainDot?: boolean;
  numberId?: React.ReactNode;
}

const EthscriptionLabel: React.FC<IEthscriptionLabel> = ({
  category,
  icon,
  collectionName,
  domainDot = true,
  numberId = null,
}) => {
  const EthscriptionViews: { [key: string]: JSX.Element } = {
    token: <Erc20View collectionName={collectionName} numberId={numberId} />,
    nft: <NftView collectionName={collectionName} icon={icon} numberId={numberId} />,
    domain: <DomainView collectionName={collectionName} domainDot={domainDot} numberId={numberId} />,
  };

  return EthscriptionViews[category] ?? null;
};

export default EthscriptionLabel;
