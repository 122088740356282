'use client';

interface INftImage {
  content: string;
}

const NftImage: React.FC<INftImage> = ({ content }) => {
  function getContentView() {
    if (content.includes('data:text/html')) {
      return (
        <iframe
          loading="lazy"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            border: 'none',
            outline: 'none',
            verticalAlign: 'middle',
            aspectRatio: '1/1',
            overflow: 'hidden',
          }}
          src={content}
          sandbox="allow-scripts"
        />
      );
    }

    if (content.includes('data:image')) {
      return (
        <img
          alt="Landscape picture"
          src={content}
          height="100%"
          width="100%"
          style={{
            objectFit: 'contain',
            border: 'none',
            outline: 'none',
            imageRendering: 'pixelated',
          }}
        />
      );
    }

    return (
      <img
        src={content}
        height="100%"
        width="100%"
        style={{
          objectFit: 'contain',
          border: 'none',
          outline: 'none',
          imageRendering: 'pixelated',
        }}
      />
    );
  }

  return getContentView();
};

export default NftImage;
