'use client';

import Erc20View from './Erc20View';
import { GetEthscriptionsItem } from '@/services/marketpalce/types';

import NftView from './NftView';
import DomainView from './DomainView';

interface IEthscriptionView {
  ethscription: GetEthscriptionsItem;
  slotCheckBox?: React.ReactNode;
}

const EthscriptionView: React.FC<IEthscriptionView> = ({ ethscription, slotCheckBox = null }) => {
  const EthscriptionViews: { [key: string]: JSX.Element } = {
    token: <Erc20View ethscription={ethscription} slotCheckBox={slotCheckBox} />,
    nft: <NftView ethscription={ethscription} slotCheckBox={slotCheckBox} />,
    domain: <DomainView ethscription={ethscription} slotCheckBox={slotCheckBox} />,
    image: <NftView ethscription={ethscription} slotCheckBox={slotCheckBox} />,
  };

  return EthscriptionViews?.[ethscription.order.category] ?? null;
};

export default EthscriptionView;
